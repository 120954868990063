import React from 'react';
import { Auth, SeoHead } from '../components';
import Landing from '../components/landing/Landing';

export default function HomePage() {
  const [auth, setAuth] = React.useState(false);

  return (
    <>
      <SeoHead title="Spacars" />
      <Auth setAuth={setAuth} />
      {!auth ? <Landing /> : null}
    </>
  );
}
